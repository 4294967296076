import {createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import {API_BASE_URL} from '../../constants'
import {ACCESS_TOKEN} from '../../constants'

export const addMeeting = createAsyncThunk('meeting/addMeeting',async (data) => {
    //startDate,endDate,description,userIds[]
    const res = await axios.post(`${API_BASE_URL}/meeting}`,{...data},
    { headers: {"Content-Type":"application/json","Authorization" : `Bearer ${ACCESS_TOKEN}`}});
    return res.data;
});

export const updateMeeting = createAsyncThunk('meeting/updateMeeting',async (data,id) => {
    //startDate,endDate,description,userIds[]
    const res = await axios.put(`${API_BASE_URL}/meeting/${id}`,{...data},
    { headers: {"Content-Type":"application/json","Authorization" : `Bearer ${ACCESS_TOKEN}`}});
    return res.data;
});

export const deleteMeeting = createAsyncThunk('meeting/deleteMeeting',async (id) => {
    const res = await axios.delete(`${API_BASE_URL}/meeting/${id}`,
    { headers: {"Authorization" : `Bearer ${ACCESS_TOKEN}`}});
    return res.data;
});

export const getAllMeetings = createAsyncThunk('meeting/getAllMeetings',async () => {
    const res = await axios.get(`${API_BASE_URL}/meeting/getAllMeetings `,
    { headers: {"Authorization" : `Bearer ${ACCESS_TOKEN}`}});
    return res.data;
});

export const getMeetingsForUser = createAsyncThunk('meeting/getMeetingsForUser',async () => {
    const res = await axios.get(`${API_BASE_URL}/meeting/getMeetingsForUser `,
    { headers: {"Authorization" : `Bearer ${ACCESS_TOKEN}`}});
    return res.data;
});

export const getMeetingsForEmployee = createAsyncThunk('meeting/getMeetingsForEmployee',async (id) => {
    const res = await axios.get(`${API_BASE_URL}/meeting/getMeetingsForEmployee/${id}`,
    { headers: {"Authorization" : `Bearer ${ACCESS_TOKEN}`}});
    return res.data;
});