import React from 'react'
import googleIcon from '../assets/icons/googleIcon.svg'
import { GOOGLE_AUTH_URL } from '../constants';


function LoginButton() {
  return (
    <>
      <a className='login-w-footer-button' href={GOOGLE_AUTH_URL}>
        <img src={googleIcon} alt='google icon' />
        Appcent İle Giriş Yap
      </a>
    </>
  )
}

export default LoginButton