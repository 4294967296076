import React from 'react'
import Mail from '../../assets/icons/mailIcon.svg'
import Phone from '../../assets/icons/phoneIcon.svg'
import Location from '../../assets/icons/locationIcon.svg'
import Green from '../../assets/icons/greenIcon.svg'
import Back from '../../assets/icons/back_black.svg'
import './index.scss'
import { useNavigate } from 'react-router-dom'

function PersonDetail(props) {
const navigate = useNavigate();

    const goBack = () => {
        navigate(`/personal`)
      }

    return (
        <>
            <div className='user-info-title'>
                <div className='user-info-title-back' onClick={goBack}>
                <img src={Back} alt='Back' className='user-info-title-img' />
                <span>Geri</span>
                </div>
                <div className='user-info-title-text'><h2>{props.user.firstName} {props.user.lastName}</h2></div>
                <div></div>
                
            </div>
            <div className='user-info'>

                <div className='user-info-main'>
                    <div className='user-info-main-content'>
                        <div>
                            {props.user.picture === null ?
                                <div className='user-info-main-content-notImage'>
                                    <span>{props.user.firstName.charAt(0).toUpperCase()}</span>
                                </div>
                                :
                                <div>
                                    <img className='user-info-main-content-hasImage' src={props.user.picture} alt='' />
                                </div>

                            }
                        </div>
                        <div className='user-info-main-content-project'>
                            <div>
                                <img src={Green} alt='' className='green-icon' />
                                <span>Aktif Proje:</span> ----
                            </div>
                            <div>
                                <span>Son Çalıştığı Proje:</span> ----
                            </div>
                            <div>
                                <span>Son Kahve Molası:</span> --.--.----- / ---- ----
                            </div>
                            <div>
                                <span>İşe Giriş Tarihi:</span> --.--.----
                            </div>
                        </div>

                        <div className='user-info-main-content-points'>
                            <div className='user-info-main-content-points__d1'>
                                <div className='left'>
                                    1 yıl <span>0</span>
                                </div>
                                <div className='right'>
                                    Yıllık Sıralama:
                                    <span>0.</span>
                                </div>
                            </div>
                            <div className='user-info-main-content-points__d2'>
                                <div className='left'>
                                    6 ay <span>0</span>
                                </div>
                                <div className='right'>
                                    6 Aylık Sıralama:
                                    <span>0.</span>
                                </div>
                            </div>
                            <div className='user-info-main-content-points__d3'>
                                <div className='left'>
                                    1 ay <span>0</span>
                                </div>
                                <div className='right'>
                                    Aylık Sıralama:
                                    <span>0.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='user-info-main-content-footer'>
                        <div className='title'>
                            <section>
                                <img src={Mail} alt='' /> E - MAIL
                                <div>{props.user.email}</div>
                            </section>
                            <section>
                                <img src={Phone} alt='' /> TELEFON
                                <div>{props.user.phoneNumber}</div>
                            </section>
                            <section>
                                <img src={Location} alt='' /> DEPARTMAN
                                <div>{props.user.department}</div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='modal-content'>
                <h1>{"props.user.displayName"}</h1>
                <br></br>
                <h1>{"props.user?.position"}</h1>
                <button className='close-button'>
                    <img src={Close} alt='' />
                </button>
                <div className='user-info-main-content'> */}
            {/* {
                        props.user.picture === null ?
                            <div className='user-info-main-content-image'>
                                <div className='user-info-main-content-image-text'>{"props.user.displayName.charAt(0).toUpperCase()"}
                                </div>
                            </div>
                            :
                            <img className='user-info-main-content-image' src={"props.user.picture"} alt='' />
                    } */}
            {/*<img src={Star} alt='' className='star' />*/}





        </>
    )
}

export default PersonDetail;
