import React from 'react'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Tabs from '../../components/Tabs'
import UserInfo from '../../components/UserInfo'
import './index.scss'
import { getUserById } from '../../redux/users/services'
import Layout from '../../components/Layout/Layout'


function PersonDetail() {
  const params = useParams();
  const dispatch = useDispatch();
  const id = params.personalId
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    if (id) {
      dispatch(getUserById(id))
    }
  }, [dispatch,id]
  )
  return (
    <>
      <Layout>
        <div className='personalDetail-main'>
          <div>
            {user && <UserInfo user={user} />}
          </div>
          <div>
            {user && <Tabs userId={user?.id} user={user} />}
          </div>
        </div>
      </Layout>


    </>
  )
}

export default PersonDetail;
