import { createSlice } from '@reduxjs/toolkit';
import {
    addTechnicalNote, getTechnicalNotes, addCoffeeBreakNote, getCoffeeBreakNotes, updateTechnicalNote, updateCoffeeBreakNote, notNumbers, noteStatusUsers,
    deleteTechnicalNote, deleteCoffeeBreakNote, hasNotNote, addCoffeeBreakComment, updateCoffeeBreakComment, getCoffeeBreakCommentByNoteId, deleteCoffeeBreakComment, addTechnicalComment, updateTechnicalComment, getTechnicalCommentByNoteId, deleteTechnicalComment
} from './services';


export const noteSlice = createSlice({
    name: 'note',
    initialState: {
        technicalNotes: [],
        coffeeBreakNotes: [],
        status: 'idle',
        error: '',
        allNotNumbers: [],
        urgentNoteUsers: [],
        nonMeetingList: [],
        commentCoffeeBreakList: [],
        commentTechnicalList: []

    },
    reducers: {
        countCommentLength: (state, action) => {
            const index = state.coffeeBreakNotes.findIndex(x => x.id === action.payload.id)
            state.coffeeBreakNotes[index].commentCount = action.payload.count
        },
        countTechCommentLength: (state, action) => {
            const index = state.technicalNotes.findIndex(x => x.id === action.payload.id)
            state.technicalNotes[index].commentCount = action.payload.count
        },
    },
    extraReducers: {
        //TechnicalNotes
        [addTechnicalNote.pending]: (state, action) => {
            state.status = "loading";
        },
        [addTechnicalNote.fulfilled]: (state, action) => {
            let data = action.payload;
            state.technicalNotes.unshift(data)
            // let newStatus = data.noteStatus
            // let tempUser = {
            //     ...data.employee, noteCounts: {
            //         1: 0, 2: 0, 3: 0
            //     }
            // }
            // tempUser.noteCounts[data.noteStatus] = tempUser.noteCounts[data.noteStatus] + 1
            // const userIndex = state.urgentNoteUsers.findIndex(x => x.userKolayIkId === data.employee.userKolayIkId)


            // if (userIndex > -1) {
            //     (state.urgentNoteUsers[userIndex].noteCounts[newStatus] = state.urgentNoteUsers[userIndex].noteCounts[newStatus] + 1)
            // } else {
            //     if (newStatus !== 3) {
            //         state.urgentNoteUsers.push(tempUser)
            //         state.nonMeetingList = state.nonMeetingList.filter(x => x.id !== tempUser.id)
            //     }
            // }

            // if (userIndex)
            //     state.allNotNumbers[newStatus] = state.allNotNumbers[newStatus] + 1;
            state.status = 'succeeded';

        },
        [addTechnicalNote.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [updateTechnicalNote.pending]: (state, action) => {
            state.status = "loading";
        },
        [updateTechnicalNote.fulfilled]: (state, action) => {

            let data = action.payload;
            const index = state.technicalNotes.findIndex(x => x.id === data.id)
            // const userIndex = state.urgentNoteUsers.findIndex(x => x.userKolayIkId === data.employee.userKolayIkId)

            // let currentStatus = state.technicalNotes[index].noteStatus
            let newStatus = data.noteStatus

            state.technicalNotes[index].technical = data.technical;
            state.technicalNotes[index].noteStatus = newStatus;

            // state.allNotNumbers[currentStatus] = state.allNotNumbers[currentStatus] - 1;
            // state.allNotNumbers[newStatus] = state.allNotNumbers[newStatus] + 1;

            // state.urgentNoteUsers[userIndex].noteCounts[currentStatus] = state.urgentNoteUsers[userIndex].noteCounts[currentStatus] - 1;
            // state.urgentNoteUsers[userIndex].noteCounts[newStatus] = state.urgentNoteUsers[userIndex].noteCounts[newStatus] + 1;

            state.status = 'succeeded';
        },
        [updateTechnicalNote.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [getTechnicalNotes.pending]: (state, action) => {
            state.status = "loading";
        },
        [getTechnicalNotes.fulfilled]: (state, action) => {
            state.technicalNotes = action.payload;
            state.status = 'succeeded';

        },
        [getTechnicalNotes.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [deleteTechnicalNote.pending]: (state, action) => {
            state.status = "loading";
        },
        [deleteTechnicalNote.fulfilled]: (state, action) => {
            let data = action.payload
            const filtered = state.technicalNotes.filter(x => x.id !== data.noteId)
            state.technicalNotes = [...filtered]
            // let tempNotNumbers = state.allNotNumbers
            // tempNotNumbers[data.noteStatus] -= 1;
            // state.allNotNumbers = tempNotNumbers;

            // let tempUrgentNoteUsers = state.urgentNoteUsers
            // const userIndex = tempUrgentNoteUsers.findIndex(x => x.userKolayIkId === data.userKolayIkId)
            // if (data.noteStatus !== 3) {
            //     state.urgentNoteUsers[userIndex].noteCounts[data.noteStatus] = state.urgentNoteUsers[userIndex].noteCounts[data.noteStatus] - 1;
            // }

            // if ((data.noteStatus !== 3) && (state.urgentNoteUsers[userIndex].noteCounts[1] === 0 && state.urgentNoteUsers[userIndex].noteCounts[2] === 0)) {
            //     state.urgentNoteUsers = state.urgentNoteUsers.filter(x => x.userKolayIkId !== data.userKolayIkId)
            // }

            state.status = 'succeeded';

        },
        [deleteTechnicalNote.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        //CoffeeBreakNotes
        [addCoffeeBreakNote.pending]: (state, action) => {
            state.status = "loading";
        },
        [addCoffeeBreakNote.fulfilled]: (state, action) => {
            let data = action.payload;
            state.coffeeBreakNotes.unshift(data)
            // let newStatus = data.noteStatus
            // let tempUser = {
            //     ...data.employee, noteCounts: {
            //         1: 0, 2: 0, 3: 0
            //     }
            // }
            // tempUser.noteCounts[data.noteStatus] = tempUser.noteCounts[data.noteStatus] + 1

            // const userIndex = state.urgentNoteUsers.findIndex(x => x.userKolayIkId === data.employee.userKolayIkId)
            // if (userIndex > -1) {
            //     (state.urgentNoteUsers[userIndex].noteCounts[newStatus] = state.urgentNoteUsers[userIndex].noteCounts[newStatus] + 1)
            // } else {
            //     if (newStatus !== 3) {
            //         state.urgentNoteUsers.push(tempUser)
            //     }

            // }

            // state.allNotNumbers[newStatus] = state.allNotNumbers[newStatus] + 1;

            state.status = 'succeeded';

        },
        [addCoffeeBreakNote.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [updateCoffeeBreakNote.pending]: (state, action) => {
            state.status = "loading";
        },
        [updateCoffeeBreakNote.fulfilled]: (state, action) => {
            let data = action.payload;
            const index = state.coffeeBreakNotes.findIndex(x => x.id === data.id)
            // const userIndex = state.urgentNoteUsers.findIndex(x => x.userKolayIkId === data.employee.userKolayIkId)

            // let currentStatus = state.coffeeBreakNotes[index].noteStatus
            let newStatus = data.noteStatus

            state.coffeeBreakNotes[index].motivation = data.motivation;
            state.coffeeBreakNotes[index].noteStatus = newStatus;

            // state.allNotNumbers[currentStatus] = state.allNotNumbers[currentStatus] - 1;
            // state.allNotNumbers[newStatus] = state.allNotNumbers[newStatus] + 1;

            // state.urgentNoteUsers[userIndex].noteCounts[currentStatus] = state.urgentNoteUsers[userIndex].noteCounts[currentStatus] - 1;
            // state.urgentNoteUsers[userIndex].noteCounts[newStatus] = state.urgentNoteUsers[userIndex].noteCounts[newStatus] + 1;

            state.status = 'succeeded';

        },
        [updateCoffeeBreakNote.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [getCoffeeBreakNotes.pending]: (state, action) => {
            state.status = "loading";
        },
        [getCoffeeBreakNotes.fulfilled]: (state, action) => {
            state.coffeeBreakNotes = action.payload;
            state.status = 'succeeded';

        },
        [getCoffeeBreakNotes.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [deleteCoffeeBreakNote.pending]: (state, action) => {
            state.status = "loading";
        },
        [deleteCoffeeBreakNote.fulfilled]: (state, action) => {
            let data = action.payload
            const filtered = state.coffeeBreakNotes.filter(x => x.id !== data.noteId)
            state.coffeeBreakNotes = [...filtered]
            // let tempNotNumbers = state.allNotNumbers
            // tempNotNumbers[data.noteStatus] -= 1;
            // state.allNotNumbers = tempNotNumbers;

            // let tempUrgentNoteUsers = state.urgentNoteUsers
            // const userIndex = tempUrgentNoteUsers.findIndex(x => x.userKolayIkId === data.userKolayIkId)
            // if (data.noteStatus !== 3) {
            //     state.urgentNoteUsers[userIndex].noteCounts[data.noteStatus] = state.urgentNoteUsers[userIndex].noteCounts[data.noteStatus] - 1;
            // }

            // if ((data.noteStatus !== 3) && (state.urgentNoteUsers[userIndex].noteCounts[1] === 0 && state.urgentNoteUsers[userIndex].noteCounts[2] === 0)) {
            //     state.urgentNoteUsers = state.urgentNoteUsers.filter(x => x.userKolayIkId !== data.userKolayIkId)
            // }

            state.status = 'succeeded';

        },
        [deleteCoffeeBreakNote.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },


        [notNumbers.pending]: (state, action) => {
            state.status = "loading";
        },
        [notNumbers.fulfilled]: (state, action) => {
            state.allNotNumbers = action.payload;
            state.status = 'succeeded';

        },
        [notNumbers.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [noteStatusUsers.pending]: (state, action) => {
            state.status = "loading";
        },
        [noteStatusUsers.fulfilled]: (state, action) => {
            state.urgentNoteUsers = action.payload;
            state.status = 'succeeded';

        },
        [noteStatusUsers.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [hasNotNote.pending]: (state, action) => {
            state.status = "loading";
        },
        [hasNotNote.fulfilled]: (state, action) => {
            state.nonMeetingList = action.payload;
            state.status = 'succeeded';

        },
        [hasNotNote.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [hasNotNote.pending]: (state, action) => {
            state.status = "loading";
        },
        [hasNotNote.fulfilled]: (state, action) => {
            state.nonMeetingList = action.payload;
            state.status = 'succeeded';

        },
        [hasNotNote.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        //Comments
        [addCoffeeBreakComment.pending]: (state, action) => {
            state.status = "loading";
        },
        [addCoffeeBreakComment.fulfilled]: (state, action) => {
            let data = action.payload;
            state.commentCoffeeBreakList.unshift(data)
            state.status = 'succeeded';

        },
        [addCoffeeBreakComment.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [updateCoffeeBreakComment.pending]: (state, action) => {
            state.status = "loading";
        },
        [updateCoffeeBreakComment.fulfilled]: (state, action) => {
            let data = action.payload;
            const index = state.commentCoffeeBreakList.findIndex(x => x.id === data.id)
            state.commentCoffeeBreakList[index] = data
            // let newStatus = data.noteStatus
            // state.coffeeBreakNotes[index].motivation = data.motivation;
            // state.coffeeBreakNotes[index].noteStatus = newStatus;
            state.status = 'succeeded';

        },
        [updateCoffeeBreakComment.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [getCoffeeBreakCommentByNoteId.pending]: (state, action) => {
            state.status = "loading";
        },
        [getCoffeeBreakCommentByNoteId.fulfilled]: (state, action) => {
            let data = action.payload;
            state.commentCoffeeBreakList = data.reverse();
            state.status = 'succeeded';

        },
        [getCoffeeBreakCommentByNoteId.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [deleteCoffeeBreakComment.pending]: (state, action) => {
            state.status = "loading";
        },
        [deleteCoffeeBreakComment.fulfilled]: (state, action) => {
            let data = action.payload
            const filtered = state.commentCoffeeBreakList.filter(x => x.id !== data)
            state.commentCoffeeBreakList = [...filtered]
            state.status = 'succeeded';
        },
        [deleteCoffeeBreakComment.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        //comment technical
        [addTechnicalComment.pending]: (state, action) => {
            state.status = "loading";
        },
        [addTechnicalComment.fulfilled]: (state, action) => {
            let data = action.payload;
            state.commentTechnicalList.unshift(data)
            state.status = 'succeeded';

        },
        [addTechnicalComment.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [updateTechnicalComment.pending]: (state, action) => {
            state.status = "loading";
        },
        [updateTechnicalComment.fulfilled]: (state, action) => {
            let data = action.payload;
            const index = state.commentTechnicalList.findIndex(x => x.id === data.id)
            state.commentTechnicalList[index] = data
            state.status = 'succeeded';

        },
        [updateTechnicalComment.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [getTechnicalCommentByNoteId.pending]: (state, action) => {
            state.status = "loading";
        },
        [getTechnicalCommentByNoteId.fulfilled]: (state, action) => {
            let data = action.payload;
            state.commentTechnicalList = data.reverse();
            state.status = 'succeeded';

        },
        [getTechnicalCommentByNoteId.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [deleteTechnicalComment.pending]: (state, action) => {
            state.status = "loading";
        },
        [deleteTechnicalComment.fulfilled]: (state, action) => {
            let data = action.payload
            const filtered = state.commentTechnicalList.filter(x => x.id !== data)
            state.commentTechnicalList = [...filtered]
            state.status = 'succeeded';
        },
        [deleteTechnicalComment.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

    }

});
const { actions } = noteSlice;
export const { countCommentLength, countTechCommentLength } = actions;
export default noteSlice.reducer;