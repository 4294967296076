import { createSlice } from '@reduxjs/toolkit';
import { getUsers, nextUsers, backUsers, sortUsers, sortNext, sortBack, filterUsers, filterNext, filterBack, searchUsers, onComingUsers, getUserProjects, getAllUser, getUserById } from './services'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        users: [],
        user:null,
        status: 'idle',
        personStatus: 'idle',
        page: 0,
        first: true,
        last: false,
        totalPages: 16,
        sort: false,
        filter: false,
        twoMonthsUsers: [],
        sixMonthsUsers: [],
        userProjects: [],
        tempUsers:[],
        tempFirstUsers:[],
        isGlobalSearch: false,
        personPageStatus:'idle',
        homeStatus:'idle'
    },
    reducers: {
       
        searchUser(state, action) {
            let searchText = action.payload
            state.users = state.tempUsers.filter(user => user.displayName.toLowerCase().includes(searchText.toLowerCase()))
            state.isGlobalSearch = true;
        },
        getDefaultUsers(state, action) {
            state.users = state.tempFirstUsers
            state.isGlobalSearch = false;
        }
       
    },
    extraReducers: {

        //getUsers
        [getUsers.pending]: (state, action) => {
            // state.personStatus = "loading";
            state.status = "loading";
        },
        [getUsers.fulfilled]: (state, action) => {
            state.page = action.payload.number;
            state.users = action.payload.content;
            state.tempFirstUsers = action.payload.content;
            state.first = action.payload.first;
            state.last = action.payload.last;
            state.totalPages = action.payload.totalPages;
            state.isGlobalSearch = false;
            // state.personStatus = 'succeeded';
            state.status  = "succeeded";

        },
        [getUsers.rejected]: (state, action) => {
            // state.personStatus = 'failed';
            state.status = 'failed';
            state.error = action.error.message;
        },

        [getUserById.pending]: (state, action) => {
            state.personStatus = "loading";
        },
        [getUserById.fulfilled]: (state, action) => {
            state.user = {...action.payload}
            state.personStatus = 'succeeded';

        },
        [getUserById.rejected]: (state, action) => {
            state.personStatus = 'failed';
            state.error = action.error.message;
        },

        //nextUsers
        [nextUsers.pending]: (state, action) => {
            state.status = "loading";
        },
        [nextUsers.fulfilled]: (state, action) => {
            state.users = action.payload.content;
            state.first = action.payload.first;
            state.totalPages = action.payload.totalPages
            state.isGlobalSearch = false;
            state.status = 'succeeded';
            if ((action.payload.totalPages) !== (state.page + 1)) {
                state.page += 1;
            }
            if ((action.payload.totalPages) === (state.page + 1)) {
                state.last = true;
            }

        },
        [nextUsers.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        //backUsers
        [backUsers.pending]: (state, action) => {
            state.status = "loading";
        },
        [backUsers.fulfilled]: (state, action) => {
            state.users = action.payload.content;
            state.status = 'succeeded';
            if (state.page !== 0) {
                state.page -= 1;
            }
            state.first = action.payload.first;
            state.last = false;
            state.isGlobalSearch = false;
        },
        [backUsers.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        //sort
        [sortUsers.pending]: (state, action) => {
            state.status = "loading";
        },
        [sortUsers.fulfilled]: (state, action) => {
            state.page = action.payload.number;
            state.users = action.payload.content;
            state.first = action.payload.first;
            state.last = action.payload.last;
            state.totalPages = action.payload.totalPages;
            state.isGlobalSearch = false;
            state.status = 'succeeded';

        },
        [sortUsers.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        //sortNext
        [sortNext.pending]: (state, action) => {
            state.status = "loading";
        },
        [sortNext.fulfilled]: (state, action) => {
            state.users = action.payload.content;
            state.first = action.payload.first;
            state.totalPages = action.payload.totalPages
            state.isGlobalSearch = false;
            state.status = 'succeeded';
            if ((action.payload.totalPages) !== (state.page + 1)) {
                state.page += 1;
            }
            if ((action.payload.totalPages) === (state.page + 1)) {
                state.last = true;
            }
        },
        [sortNext.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
        //sortBack
        [sortBack.pending]: (state, action) => {
            state.status = "loading";
        },
        [sortBack.fulfilled]: (state, action) => {
            state.users = action.payload.content;
            state.status = 'succeeded';
            if (state.page !== 0) {
                state.page -= 1;
            }
            state.first = action.payload.first;
            state.last = false;
            state.isGlobalSearch = false;
        },
        [sortBack.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        //filter
        [filterUsers.pending]: (state, action) => {
            state.status = "loading";
        },
        [filterUsers.fulfilled]: (state, action) => {
            state.page = action.payload.number;
            state.users = action.payload.content;
            state.first = action.payload.first;
            state.last = action.payload.last;
            state.totalPages = action.payload.totalPages;
            state.isGlobalSearch = false;
            state.status = 'succeeded';

        },
        [filterUsers.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        //filterNext
        [filterNext.pending]: (state, action) => {
            state.status = "loading";
        },
        [filterNext.fulfilled]: (state, action) => {
            state.users = action.payload.content;
            state.first = action.payload.first;
            state.totalPages = action.payload.totalPages
            state.isGlobalSearch = false;
            state.status = 'succeeded';
            if ((action.payload.totalPages) !== (state.page + 1)) {
                state.page += 1;
            }
            if ((action.payload.totalPages) === (state.page + 1)) {
                state.last = true;
            }
        },
        [filterNext.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
        //filterBack
        [filterBack.pending]: (state, action) => {
            state.status = "loading";
        },
        [filterBack.fulfilled]: (state, action) => {
            state.users = action.payload.content;
            state.status = 'succeeded';
            if (state.page !== 0) {
                state.page -= 1;
            }
            state.first = action.payload.first;
            state.last = false;
            state.isGlobalSearch = false;
        },
        [filterBack.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },
        //search
        [searchUsers.pending]: (state, action) => {
            state.status = "loading";
        },
        [searchUsers.fulfilled]: (state, action) => {
            state.users = action.payload;
            state.isGlobalSearch = false;
            state.status = 'succeeded';
        },
        [searchUsers.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },


        [onComingUsers.pending]: (state, action) => {
            state.homeStatus = "loading";
        },
        [onComingUsers.fulfilled]: (state, action) => {
            state.twoMonthsUsers = [...action.payload.startDateForTwoMonths]
            state.sixMonthsUsers = [...action.payload.startDateForSixMonths]
            // const currentDate = new Date()
            // state.twoMonthsUsers = action.payload.filter
            // (x=>(Math.abs(currentDate-new Date(x.startDate)))/(1000 * 60 * 60 * 24)>45 && (Math.abs(currentDate-new Date(x.startDate)))/(1000 * 60 * 60 * 24)<60)
            // state.sixMonthsUsers = action.payload.filter
            // (x=>(Math.abs(currentDate-new Date(x.startDate)))/(1000 * 60 * 60 * 24)>165 && (Math.abs(currentDate-new Date(x.startDate)))/(1000 * 60 * 60 * 24)<180)
            state.homeStatus = 'succeeded';
        },
        [onComingUsers.rejected]: (state, action) => {
            state.homeStatus = 'failed';
            state.error = action.error.message;
        },

        [getUserProjects.pending]: (state, action) => {
            state.personStatus = "loading";
        },
        [getUserProjects.fulfilled]: (state, action) => {
            state.userProjects = [...action.payload]
            state.personStatus = 'succeeded';

        },
        [getUserProjects.rejected]: (state, action) => {
            state.personStatus = 'failed';
            state.error = action.error.message;
        },

        [getAllUser.pending]: (state, action) => {
            // state.status = "loading";
            state.status = "loading";

        },
        [getAllUser.fulfilled]: (state, action) => {
            state.tempUsers = [...action.payload]
            state.isGlobalSearch = false;
            // state.status = 'succeeded';
            state.status = "succeeded";

        },
        [getAllUser.rejected]: (state, action) => {
            // state.status = 'failed';
            state.status = "failed";

            state.error = action.error.message;
        },

    }
});

export const {searchUser, getDefaultUsers} = userSlice.actions;
export default userSlice.reducer;