import {createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import {API_BASE_URL} from '../../constants'
import {ACCESS_TOKEN} from '../../constants'

export const getMentorsOfEmployee = createAsyncThunk('mentor/getMentorsOfEmployee',async (id) => {
    const res = await axios.get(`${API_BASE_URL}/getMentors/of/${id}`,
    { headers: {"Authorization" : `Bearer ${ACCESS_TOKEN}`}});
    return res.data;
});

export const removeMentorOfEmployee = createAsyncThunk('mentor/removeMentorOfEmployee',async ({empId,data}) => {
     await axios.delete(`${API_BASE_URL}/remove/${empId}/from/${data.id}`,
    { headers: {"Content-Type":"application/json","Authorization" : `Bearer ${ACCESS_TOKEN}`}});
    return data;
});


export const addMentorOfEmployee = createAsyncThunk('mentor/addMentorOfEmployee',async ({empId,data}) => {
     await axios.post(`${API_BASE_URL}/assign/${empId}/to/${data.id}`,{},
    { headers: {"Content-Type":"application/json","Authorization" : `Bearer ${ACCESS_TOKEN}`}});
    return data;
});


export const getAllMentors = createAsyncThunk('mentor/getAllMentors',async () => {
    const res = await axios.get(`${API_BASE_URL}/getMentors`,
    { headers: {"Authorization" : `Bearer ${ACCESS_TOKEN}`}});
    return res.data;
});























