import React, { useEffect } from 'react'
import './index.scss'
import { useSelector, useDispatch } from 'react-redux'
import { onComingUsers } from '../../redux/users/services';
import FullNameLine from '../FullNameLine';


function CriticalMeeting() {
  const dispatch = useDispatch();
  const twoMonthsUsers = useSelector((state) => state.user.twoMonthsUsers);
  const sixMonthsUsers = useSelector((state) => state.user.sixMonthsUsers);

  useEffect(() => {
    dispatch(onComingUsers());
  }, [dispatch]);



  return (
    <>
      <section className='critical-w'>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
          <h2>Kritik Toplantılar</h2>
        </div>
        <div style={{ display: "flex" }}>
          <div>
            <h4>2.ayı dolanlar</h4>
            {
              twoMonthsUsers.length > 0 ?
                twoMonthsUsers.map((user) => (
                  <div key={user.id}>
                    <FullNameLine user={user} />
                  </div>
                )
                )
                :
                <div className='noData'>Böyle bir çalışan yok</div>
            }
          </div>
          <div>
            <h4>6.ayı dolanlar</h4>
            {
              sixMonthsUsers.length > 0 ?
                sixMonthsUsers.map((user) => (
                  <div key={user.id}>
                    <FullNameLine user={user} />
                  </div>
                )
                )
                :
                <div className='noData'>Böyle bir çalışan yok</div>

            }
          </div>

        </div>


      </section>
    </>
  )
}

export default CriticalMeeting
