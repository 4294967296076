import { Navigate  } from 'react-router-dom'
import axios from 'axios'
import { API_BASE_URL } from './constants';

function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

    var results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export function Logout(){
  localStorage.clear();
  return window.location.href = "/home";
}

export  function OAuth2RedirectHandler() {

        const token = getUrlParameter('token');

        if(token) {

            localStorage.setItem("token", token);


            const config = {
                headers: { 'Authorization': `Bearer ` +  token }
              };

            axios.get( 
                API_BASE_URL + '/profile',
              config
              ).then(res => {
                localStorage.setItem("picture", res.data.picture);
                localStorage.setItem("name", res.data.firstName + " " + res.data.lastName);
                localStorage.setItem("email", res.data.email);
                localStorage.setItem("department", res.data.department);
                localStorage.setItem("position", res.data.position);
              }).then(res => {
                return window.location.href = "/home";
              }).catch(console.log);

      
              

        } else {
            return <Navigate to={{
                pathname: "/login",
            }}/>; 
        }
}