import React from 'react'
import { useNavigate } from 'react-router-dom';
import './index.scss'

function UrgentNoteList({ user }) {
  // const [modal, setModal] = useState(false)
  // const toggleModal = () => setModal(!modal)
  const navigate = useNavigate();

  const goDetail = (id) => {
    navigate(`/personal/${id}`)
  }

  return (
    <>
      <section className='urgent-note-list'>

        <div className='urgent-note-list-content'>
          <div onClick={() => goDetail(user.id)} className='text'>
            {
              user.picture === null ?
                <div className='urgent-note-list-image'>
                  <div className='urgent-note-list-image-text'>{user.firstName.charAt(0).toUpperCase()}
                  </div>
                </div>
                :
                <img src={user.picture} alt='user' />
            }
            <h3>{user.displayName ?? user.firstName + " " + user.lastName}</h3>
          </div>
          <div className='square'>
            <div className='square-red'>
              <p>{user.noteCounts["1"]}</p>
            </div>
            <div className='square-yellow'>
              <p>{user.noteCounts["2"]}</p>
            </div>
          </div>
        </div>

      </section>
      {/* {modal && <Modal user={user} state={(modal, setModal)} />} */}
    </>
  )
}

export default UrgentNoteList
