import React, { useState, useEffect } from 'react'
import Layout from '../../components/Layout/Layout'
import Search from '../../assets/icons/searchIcon.svg'
import './index.scss'
import Filter from '../../assets/icons/filterIcon.svg'
import List from '../../assets/icons/listIcon.svg'
import Next from '../../assets/icons/next.svg'
import Back from '../../assets/icons/back.svg'
import { useSelector, useDispatch } from 'react-redux'
import { getUsers, backUsers, nextUsers, filterUsers, filterBack, filterNext, sortUsers, sortBack, sortNext, searchUsers, getAllUser } from '../../redux/users/services'
// import MentorList from '../../components/MentorList'
import { Navigate ,useNavigate } from 'react-router-dom'
import { getDefaultUsers, searchUser } from '../../redux/users/userSlice'


function Person() {
  const [isFilter, setIsFilter] = useState(false)
  const [isSort, setIsSort] = useState(false)
  const [isSearch, setIsSearch] = useState(false)
  const [url, setUrl] = useState('')
  const [user, setUser] = useState([])
  // const toggleModal = () => setModal(!modal)
  const users = useSelector(state => state.user.users);
  const page = useSelector((state) => state.user.page);
  const first = useSelector((state) => state.user.first);
  const last = useSelector((state) => state.user.last);
  const totalPages = useSelector((state) => state.user.totalPages);
  const status = useSelector((state) => state.user.status);
  const error = useSelector((state) => state.user.error);
  const isGlobalSearch = useSelector((state) => state.user.isGlobalSearch);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (status === 'idle') {
      dispatch(getUsers())
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(getAllUser())
    }
  }, [dispatch, status]);

  useEffect(() => {

    if (status !== 'idle') {
      isGlobalSearch === true ? setIsSearch(true) : setIsSearch(false)
    }
  }, [status, isGlobalSearch]);


  if (status === 'failed') {
    return <Navigate to={{
      pathname: "/logout",
    }} />;
  }

  if (error) {
    return <div>Error: {error}</div>
  }


  const getSearchedUsers = async (event) => {
    let searchText = event.target.value
    setUrl('')
    if (searchText.length >= 3) {
      await setIsSearch(true)
      await setIsFilter(false)
      await setIsSort(false)
      // await dispatch(searchUsers(searchText))
      await dispatch(searchUser(searchText))

    } else {
      await setIsSearch(false)
      // await dispatch(getUsers())
      await dispatch(getDefaultUsers())

    }

  }

  const getAlphabeticAZ = async () => {
    let preventUrl = url;
    await setUrl('')
    await setIsSort(true)
    await setIsFilter(false);
    await setIsSearch(false)
    await setUrl('sort=firstName,asc&' + preventUrl)
    let obj = {
      page: undefined,
      url: 'sort=firstName,asc&' + preventUrl
    }
    await dispatch(sortUsers(obj))
  }
  const getAlphabeticZA = async () => {
    let preventUrl = url;
    await setUrl('')
    await setIsSort(true)
    await setIsFilter(false);
    await setIsSearch(false)
    await setUrl('sort=firstName,desc&' + preventUrl)
    let obj = {
      page: undefined,
      url: 'sort=firstName,desc&' + preventUrl
    }
    await dispatch(sortUsers(obj))
  }
  const getPositionAZ = async () => {
    let preventUrl = url;
    await setUrl('')
    await setIsSort(true)
    await setIsFilter(false);
    await setIsSearch(false)
    await setUrl('sort=position,asc&' + preventUrl)
    let obj = {
      page: undefined,
      url: 'sort=position,asc&' + preventUrl
    }
    await dispatch(sortUsers(obj))

  }
  const getPositionZA = async () => {
    let preventUrl = url;
    await setUrl('')
    await setIsSort(true)
    await setIsFilter(false);
    await setIsSearch(false);
    await setUrl('sort=position,desc&' + preventUrl)
    let obj = {
      page: undefined,
      url: 'sort=position,desc&' + preventUrl
    }
    await dispatch(sortUsers(obj))

  }

  const getUsersAll = async () => {

    await setIsFilter(true);
    await setIsSort(false)
    await setIsSearch(false)
    await setUrl('')
    let obj = {
      page: undefined,
      url: 'sort=firstName,asc'
    }
    await dispatch(filterUsers(obj))
  }
  const getManager = async () => {
    await setIsFilter(true);
    await setIsSort(false)
    await setIsSearch(false)
    await setUrl('positions=Yönetici')
    let obj = {
      page: undefined,
      url: 'positions=Yönetici'
    }
    await dispatch(filterUsers(obj))

  }

  const getIos = async () => {
    await setIsFilter(true);
    await setIsSort(false)
    await setIsSearch(false)
    await setUrl('positions=ios')
    let obj = {
      page: undefined,
      url: 'positions=ios'
    }
    await dispatch(filterUsers(obj))
  }
  const getAndroid = async () => {
    await setUrl('')
    await setIsFilter(true);
    await setIsSort(false)
    await setIsSearch(false)
    await setUrl('positions=Android')
    let obj = {
      page: undefined,
      url: 'positions=Android'
    }
    await dispatch(filterUsers(obj))
  }
  const getBackend = async () => {
    await setUrl('')
    await setIsFilter(true);
    await setIsSort(false)
    await setIsSearch(false)
    await setUrl('positions=.net&positions=java')
    let obj = {
      page: undefined,
      url: 'positions=.net&positions=java'
    }
    await dispatch(filterUsers(obj))
  }


  const getFrontend = async () => {
    await setIsFilter(true);
    await setIsSort(false)
    await setIsSearch(false)
    await setUrl('positions=Front')
    let obj = {
      page: undefined,
      url: 'positions=Front'
    }
    await dispatch(filterUsers(obj))
  }
  const getTest = async () => {
    await setIsFilter(true);
    await setIsSort(false)
    await setIsSearch(false)
    await setUrl('positions=test')
    let obj = {
      page: undefined,
      url: 'positions=test'
    }
    await dispatch(filterUsers(obj))
  }

  const goDetail = (id) => {
    navigate(`/personal/${id}`)
  }

  return (
    <div className='home-w'>
      <Layout>
        <section className='filter'>
          <div className='filter-hw'>
            <div className='filter-hw-header'>
              <input type='text' onChange={getSearchedUsers} />
              <img src={Search} alt='' />
            </div>
            <div className='filter-hw-buttons'>

              <div className="dropdown">
                <button className="dropbtn">
                  <img src={List} alt='' />
                </button>
                <div className="dropdown-content">
                  <button onClick={getAlphabeticAZ}>İsim A-Z</button>
                  <button onClick={getAlphabeticZA}>İsim Z-A</button>
                  <button onClick={getPositionAZ}>Ünvan A-Z</button>
                  <button onClick={getPositionZA}>Ünvan Z-A</button>
                </div>
              </div>

              <div className="dropdown">
                <button className="dropbtn">
                  <img src={Filter} alt='' />
                </button>
                <div className="dropdown-content">
                  <button onClick={getUsersAll}>Tüm Liste</button>
                  <button onClick={getManager}>Yöneticiler</button>
                  <button onClick={getIos}>IOS</button>
                  <button onClick={getAndroid}>Android</button>
                  <button onClick={getBackend}>Backend</button>
                  <button onClick={getFrontend}>Frontend</button>
                  <button onClick={getTest}>Test</button>
                </div>
              </div>
            </div>
          </div>

          <div className='filter-content-header'>
            <h3 className='first'>İsim</h3>
            <h3 className='second'>Unvan</h3>
            <h3 className='third'>E-posta</h3>
          </div>

          <div className='filter-content'>
            {users.map((user) => (
              <div key={user.id} onClick={() =>{setUser(user);goDetail(user.id);}} className='filter-content-data'>
                <div className='first'>
                    {
                        user.picture === null ?
                        <div className='first-image'>
                          <div className='first-image-text'>{user.displayName.charAt(0).toUpperCase()}</div>
                        </div> :
                        <div>
                          <img src={user.picture} alt='' />
                        </div>
                    }
                    <p>{user.displayName}</p>
                </div>

                <div className='second'>
                    <span>{user.position}</span>
                </div>
                <div className='third'>{user.email}</div>
                {/* <div className='fourth'>
                    <button onClick={togglePopup}>
                       <img width={22} height={22} src={PersonIcon2} alt='Edit Mentor' />
                    </button>
                </div> */}
                {/* </Link> */}
                {/* </Link> */}
              </div>
            ))
            }
          </div>

          {/* {showPopup ? <MentorList id={user.id} text="Close me" closePopup={togglePopup} />
            : null } */}

          <div className='filter-content-pagination'>


            {status !== 'loading' && !isFilter && !isSort && !isSearch && (!first ? <button className='back' onClick={() => dispatch(backUsers(page))}><img src={Back} alt='' /></button> :
              <button className='back'><img src={Back} alt='' /></button>)}
            {status !== 'loading' && isSort && !isFilter && !isSearch && (!first ? <button className='back' onClick={() => dispatch(sortBack({ page, url }))}><img src={Back} alt='' /></button> :
              <button className='back'><img src={Back} alt='' /></button>)}
            {status !== 'loading' && isFilter && !isSort && !isSearch && (!first ? <button className='back' onClick={() => dispatch(filterBack({ page, url }))}><img src={Back} alt='' /></button> :
              <button className='back'><img src={Back} alt='' /></button>)}
            {status !== 'loading' && !isFilter && !isSort && isSearch && (!first ? <button className='back'><img src={Back} alt='' /></button> :
              <button className='back'><img src={Back} alt='' /></button>)}


            <div className=''>
              {status !== 'loading' && !isFilter && !isSort && !isSearch && Array.from(Array(totalPages).keys()).map((val) => {
                return <button key={val} onClick={() => dispatch(getUsers(val))}>
                  <p className={page === val ? 'active' : ''}>{val + 1}</p></button>
              })}
            </div>
            <div className=''>
              {status !== 'loading' && isSort && Array.from(Array(totalPages).keys()).map((valS) => {
                return <button key={valS} onClick={() => dispatch(sortUsers({ page: valS, url }))}>
                  <p className={page === valS ? 'active' : ''}>{valS + 1}</p></button>
              })}
            </div>
            <div className=''>
              {status !== 'loading' && isFilter && Array.from(Array(totalPages).keys()).map((valF) => {
                return <button key={valF} onClick={() => dispatch(filterUsers({ page: valF, url }))}>
                  <p className={page === valF ? 'active' : ''}>{valF + 1}</p></button>
              })}
            </div>
            <div className=''>
              {status !== 'loading' && isSearch && <button>
                <p className={'active'}>{1}</p></button>}
            </div>


            {status !== 'loading' && !isFilter && !isSort && !isSearch && (!last ? <button className='next' onClick={() => dispatch(nextUsers(page))}><img src={Next} alt='' /></button> :
              <button className='next'><img src={Next} alt='' /></button>)}
            {status !== 'loading' && isSort && !isFilter && !isSearch && (!last ? <button className='next' onClick={() => dispatch(sortNext({ page, url }))}><img src={Next} alt='' /></button> :
              <button className='next'><img src={Next} alt='' /></button>)}
            {status !== 'loading' && isFilter && !isSort && !isSearch && (!last ? <button className='next' onClick={() => dispatch(filterNext({ page, url }))}><img src={Next} alt='' /></button> :
              <button className='next'><img src={Next} alt='' /></button>)}
            {status !== 'loading' && !isFilter && !isSort && isSearch && (!last ? <button className='next'><img src={Next} alt='' /></button> :
              <button className='next'><img src={Next} alt='' /></button>)}
          </div>

        </section>
      </Layout>
    </div >
  )
}

export default Person
