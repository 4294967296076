import React from 'react'
import Header from '../Header'
import Sides from '../Sides'

function Layout({children}) {
  return (
    <>
      <Sides />
      <section className='home-w-right'>
        <Header />
        <main className='home-w-main'>{children}</main>
      </section>
    </>
  )
}

export default Layout
