import React from 'react'
import {Link} from 'react-router-dom'
import appcentLogo from '../assets/icons/appcentLogo.svg'
import homeIcon from '../assets/icons/homeIcon.svg'
import calendarIcon from '../assets/icons/calendarIcon.svg'
import meetingIcon from '../assets/icons/meetingIcon.svg'
import personIcon from '../assets/icons/personIcon.svg'
import settingsIcon from '../assets/icons/settingsIcon.svg'
import {useLocation} from 'react-router-dom'

function Sides() {
  const location = useLocation()
  const {pathname} = location
  const splitLocation = pathname.split('/')
  return (
    <>
      <aside className='home-w-aside'>
        <img src={appcentLogo} alt='Appcent Logo' />

        <div className='home-w-aside-img'>
          <Link to='/home' className={splitLocation[1] === 'home' ? 'active' : ''}>
            <img src={homeIcon} alt='Home Icon' />
          </Link>
          <Link to='/calendar' className={splitLocation[1] === 'calendar' ? 'active' : ''}>
            <img src={calendarIcon} alt='Calendar Icon' />
          </Link>
          <Link to='/meeting' className={splitLocation[1] === 'meeting' ? 'active' : ''}>
            <img src={meetingIcon} alt='Meeting Icon' />
          </Link>
          <Link to='/personal' className={splitLocation[1] === 'personal' ? 'active' : ''}>
            <img src={personIcon} alt='Person Icon' />
          </Link>
          <Link to='/settings' className={splitLocation[1] === 'settings' ? 'active' : ''}>
            <img src={settingsIcon} alt='Settings Icon' />
          </Link>
        </div>
      </aside>
    </>
  )
}

export default Sides
