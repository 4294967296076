import React from 'react'
import Layout from '../../components/Layout/Layout'

function Meeting() {
  return (
    <div className='home-w'>
      <Layout>Çok Yakında :)</Layout>
    </div>
  )
}

export default Meeting
