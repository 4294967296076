import {createSlice} from '@reduxjs/toolkit';
import {addMeeting,deleteMeeting,getAllMeetings,getMeetingsForEmployee,getMeetingsForUser,updateMeeting} from './services'



export const meetingSlice = createSlice({
    name: 'meeting',
    initialState: {
        status:'idle',
        error:'',
    },
    reducers:{
    },
    extraReducers:{

        [addMeeting.pending]: (state, action) => {
            state.status = "loading";
        },
        [addMeeting.fulfilled]: (state, action) => {
            state.status = 'succeeded';
        },
        [addMeeting.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [deleteMeeting.pending]: (state, action) => {
            state.status = "loading";
        },
        [deleteMeeting.fulfilled]: (state, action) => {
            state.status = 'succeeded';
        },
        [deleteMeeting.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [getAllMeetings.pending]: (state, action) => {
            state.status = "loading";
        },
        [getAllMeetings.fulfilled]: (state, action) => {
            state.status = 'succeeded';
        },
        [getAllMeetings.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [getMeetingsForEmployee.pending]: (state, action) => {
            state.status = "loading";
        },
        [getMeetingsForEmployee.fulfilled]: (state, action) => {
            state.status = 'succeeded';
        },
        [getMeetingsForEmployee.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [getMeetingsForUser.pending]: (state, action) => {
            state.status = "loading";
        },
        [getMeetingsForUser.fulfilled]: (state, action) => {
            state.status = 'succeeded';
        },
        [getMeetingsForUser.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

        [updateMeeting.pending]: (state, action) => {
            state.status = "loading";
        },
        [updateMeeting.fulfilled]: (state, action) => {
            state.status = 'succeeded';
        },
        [updateMeeting.rejected]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        },

    
    }

});

export default meetingSlice.reducer;