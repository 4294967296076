import {createSlice} from '@reduxjs/toolkit';
import { addMentorOfEmployee, getAllMentors, getMentorsOfEmployee, removeMentorOfEmployee } from './services';

export const mentorSlice = createSlice({
    name: 'mentor',
    initialState: {
        mentors:[],
        status:'idle',
        error:'',
        allMentors:[]
    },
    reducers:{

    },
    extraReducers:{
        [getMentorsOfEmployee.pending]:(state,action)=>{
            state.status = "loading";
        },
        [getMentorsOfEmployee.fulfilled]:(state,action)=>{
            state.mentors=action.payload;
            state.status = 'succeeded';
          
        },
        [getMentorsOfEmployee.rejected]:(state,action)=>{
            state.status = 'failed';
            state.error = action.error.message;
        },

        [addMentorOfEmployee.pending]:(state,action)=>{
            state.status = "loading";
        },
        [addMentorOfEmployee.fulfilled]:(state,action)=>{
            state.mentors.push(action.payload);
            const id = action.payload.id;
            const filtered = state.allMentors.filter(x => x.id !== id)
            state.allMentors = filtered
            state.status = 'succeeded';
        },
        [addMentorOfEmployee.rejected]:(state,action)=>{
            state.status = 'failed';
            state.error = action.error.message;
        },

        [removeMentorOfEmployee.pending]:(state,action)=>{
            state.status = "loading";
        },
        [removeMentorOfEmployee.fulfilled]:(state,action)=>{
            const id = action.payload.id;
            const filtered = state.mentors.filter(x => x.id !== id)
            state.mentors = filtered
            state.allMentors.push(action.payload);
            state.status = 'succeeded'; 
        },
        [removeMentorOfEmployee.rejected]:(state,action)=>{
            state.status = 'failed';
            state.error = action.error.message;
        },


        [getAllMentors.pending]:(state,action)=>{
            state.status = "loading";
        },
        [getAllMentors.fulfilled]:(state,action)=>{
            let a = action.payload
            let b = state.mentors
            const res1 = a.filter((page1) => !b.find(page2 => page1.id === page2.id )) 
            state.allMentors = res1;
            state.state = "succeeded"
          
        },
        [getAllMentors.rejected]:(state,action)=>{
            state.status = 'failed';
            state.error = action.error.message;
        },
    }

});

export default mentorSlice.reducer;