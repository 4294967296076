import '../app.scss'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Login from './auth/Login'
import Home from './Home'
import Calendar from './Calendar'
import Meeting from './Meeting'
import Person from './Person'
import Settings from './Settings'
import PersonDetail from './PersonDetail'
const {OAuth2RedirectHandler, Logout} = require('../login');


function App() {   

  if(!localStorage.token){
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/oauth2/redirect" element={<OAuth2RedirectHandler />} />
          <Route path='*' element={<Login />} />
        </Routes>
      </BrowserRouter>
    )

  }


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/logout" element={<Logout />} />
        <Route path='/' element={<Home />} />
        <Route path='/home' element={<Home />} />
        <Route path='/calendar' element={<Calendar />} />
        <Route path='/meeting' element={<Meeting />} />
        <Route path='/personal' element={<Person />} />
        <Route path='/settings' element={<Settings />} />
        <Route path='/personal/:personalId' element={<PersonDetail />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
