import React from 'react'
import './index.scss'

function CommentModal(props) {
    return (props.trigger) ? (
        <div>
            <div className='comment-modal'>
                <div className='comment-modal-inner'>
                   {props.children}
                </div>
            </div>
        </div>
    ) : "";
}

export default CommentModal;
