import React from 'react'
import './index.scss'
// import Modal from '../Modal'
import { useNavigate } from 'react-router-dom';

function FullNameLine({user}) {
  // const [modal, setModal] = useState(false)
  // const toggleModal = () => setModal(!modal)
  const navigate = useNavigate();

  const goDetail = (id) => {
    navigate(`/personal/${id}`)
  }

  return (
    <>
      <section className='fullName-line '>
        
           {
            user.picture === null ? 
            <div className='fullName-line-image'>
                  <div className='fullName-line-image-text'>{user.displayName.charAt(0).toUpperCase()}
                  </div>
                </div>
                 :
                <img src={user.picture} alt='user' />
              }
        
        <div onClick={()=>goDetail(user.id)} className='fullName-line-content'>
          <h3>{user.displayName}</h3>
        </div>
      </section>
      {/* {modal && <Modal user={user} state={(modal, setModal)} />} */}
    </>
  )
}

export default FullNameLine
