import React from 'react'
import {Link} from 'react-router-dom'
import profile from '../assets/icons/profile.svg'
import downArrowIcon from '../assets/icons/downArrowIcon.svg'


function Header() {


      return (
        <>
          <header className='home-w-header'>
            <nav className='home-w-header--nav'>
              <div className='profile'>
                <img src={localStorage.picture} alt='' className='picture' />
                <p>{localStorage.name}<br /><small>{localStorage.email}</small></p>
                <img src={downArrowIcon} alt='' className='icon' />
                <div className="dropdown-content">
                <Link to='/logout' >Çıkış</Link>
                </div>
              </div>
            </nav>
          </header>
        </>
      )

}

export default Header
