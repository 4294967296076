import React, { useEffect } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { useSelector, useDispatch } from 'react-redux'
import {  notNumbers } from '../redux/notes/services';


export default function PieChart() {
  const dispatch = useDispatch();
  const allNotNumbers = useSelector((state) => state.note.allNotNumbers);
  useEffect( ()=> {
      dispatch(notNumbers());
  }, [dispatch]);


   const data = {
    labels:  ['Kırmızı Notlar', 'Sarı Notlar', 'Yeşil Notlar'],
    datasets: [
      {
        data: Object.values(allNotNumbers),
        backgroundColor: ['#E43D30', '#FBC119', '#229342'],
        hoverBackgroundColor: ['#E43D30', '#FBC119', '#229342'],
        borderWidth: 1,
      },
    ],
  }

  const options = {
      legend: {
        position: 'top',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          fontSize:13.5,
          fontStyle:'bolder',
          fontColor:'#000000'
        }
      }
    }
  
  

  const onClick = (e) => {
    console.log(e)
  }
  return <Doughnut data={data} options={options}  onClick={(e) => onClick(e)} />
  


  
      
  
   
}
