import {createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import {API_BASE_URL} from '../../constants'
import {ACCESS_TOKEN} from '../../constants'

const limit = 9;

export const getUsers = createAsyncThunk('user/getUsers',async (page=0) => {
    const res = await axios.get(`${API_BASE_URL}/employees?page=${page}&size=${limit}`,
    { headers: {"Content-Type":"application/json","Authorization" : `Bearer ${ACCESS_TOKEN}`} });
    return res.data; 
});
export const getAllUser = createAsyncThunk('user/getAllUser',async () => {
    const res = await axios.get(`${API_BASE_URL}/employeesSearch?page=0&size=300`,
    { headers: {"Content-Type":"application/json","Authorization" : `Bearer ${ACCESS_TOKEN}`} });
    return res.data.content; 
});


export const nextUsers = createAsyncThunk('user/nextUsers',async (page) => {
  const res = await axios.get(`${API_BASE_URL}/employees?page=${page+1}&size=${limit}`,
  { headers: {"Content-Type":"application","Authorization" : `Bearer ${ACCESS_TOKEN}`} });
  return res.data; 
  
});
export const backUsers = createAsyncThunk('user/backUsers',async (page) => {
    const res = await axios.get(`${API_BASE_URL}/employees?page=${page-1}&size=${limit}`,
    { headers: {"Content-Type":"application","Authorization" : `Bearer ${ACCESS_TOKEN}`} });
    return res.data;
});


export const sortUsers= createAsyncThunk('user/sortUsers',async({page=0,url}) => {
    const res = await axios.get(`${API_BASE_URL}/employees?page=${page}&${url}&size=${limit}`,
    { headers: {"Content-Type":"application/json","Authorization" : `Bearer ${ACCESS_TOKEN}`} });
    return res.data;
});
export const sortNext= createAsyncThunk('user/sortNext',async({page,url}) => {
    const res = await axios.get(`${API_BASE_URL}/employees?page=${page+1}&${url}&size=${limit}`,
    { headers: {"Content-Type":"application/json","Authorization" : `Bearer ${ACCESS_TOKEN}`} });
    return res.data;
});
export const sortBack= createAsyncThunk('user/sortBack',async({page,url}) => {
    const res = await axios.get(`${API_BASE_URL}/employees?page=${page-1}&${url}&size=${limit}`,
    { headers: {"Content-Type":"application/json","Authorization" : `Bearer ${ACCESS_TOKEN}`} });
    return res.data;
});

export const filterUsers= createAsyncThunk('user/filterUsers',async({page=0,url}) => {
    const res = await axios.get(`${API_BASE_URL}/employees?page=${page}&${url}&size=${limit}`,
    { headers: {"Content-Type":"application/json","Authorization" : `Bearer ${ACCESS_TOKEN}`} });
    return res.data;
});
export const filterNext= createAsyncThunk('user/filterNext',async({page,url}) => {
    const res = await axios.get(`${API_BASE_URL}/employees?page=${page+1}&${url}&size=${limit}`,
    { headers: {"Content-Type":"application/json","Authorization" : `Bearer ${ACCESS_TOKEN}`} });
    return res.data;
});
export const filterBack= createAsyncThunk('user/filterBack',async({page,url}) => {
    const res = await axios.get(`${API_BASE_URL}/employees?page=${page-1}&${url}&size=${limit}`,
    { headers: {"Content-Type":"application/json","Authorization" : `Bearer ${ACCESS_TOKEN}`} });
    return res.data;
});

export const searchUsers= createAsyncThunk('user/searchUsers',async(parameter) => {
    const res = await axios.get(`${API_BASE_URL}/employees/search?field=displayName&terms=${parameter}`,
    { headers: {"Content-Type":"application/json","Authorization" : `Bearer ${ACCESS_TOKEN}`} });
    return res.data;
});


export const onComingUsers = createAsyncThunk('user/onComingUsers',async () => {
    const res = await axios.get(`${API_BASE_URL}/getEmp/getUsersByStartDate`,
    { headers: {"Content-Type":"application/json","Authorization" : `Bearer ${ACCESS_TOKEN}`} });
    return res.data;
});


export const getUserProjects = createAsyncThunk('user/getUserProjects',async (userId) => {
    const res = await axios.get(`${API_BASE_URL}/getProject/getProjectsForEmployee?id=${userId}`,
    { headers: {"Content-Type":"application/json","Authorization" : `Bearer ${ACCESS_TOKEN}`} });
    return res.data;
});

export const getUserById = createAsyncThunk('user/getUserById',async (userId) => {
    const res = await axios.get(`${API_BASE_URL}/employee/${userId}/profile`,
    { headers: {"Content-Type":"application/json","Authorization" : `Bearer ${ACCESS_TOKEN}`} });
    return res.data;
});

