import { configureStore } from '@reduxjs/toolkit'
import userSlice from './users/userSlice';
import noteSlice from './notes/noteSlice';
import mentorSlice from './mentors/mentorSlice';
import pointSlice from './point/pointSlice';
import meetingSlice from './meetings/meetingSlice'

export const store = configureStore({
  reducer: {
    user: userSlice,
    note: noteSlice,
    mentor:mentorSlice,
    point:pointSlice,
    meeting: meetingSlice
  },
})