import React from 'react'
import './Login.scss'
import Icon from '../../assets/icons/coffeeIcon.svg'
import LoginButton from '../../components/LoginButton'

function Login() {
  return (
    <div className='login'>
      <div className='login-w'>
        <div className='login-w-header'>
          <img src={Icon} alt='Coffee Break Icon' />
          <h4 className='title-name'>
            Appcent <br></br> <span className='coffee'>Coffee</span> Break
          </h4>
        </div>
        <div className='login-w-footer'>
          <LoginButton />
        </div>
      </div>
    </div>
  )
}

export default Login;
