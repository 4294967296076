import React from 'react'
import './index.scss'
import { useState, useEffect } from 'react'
import './index.scss'
import { useSelector, useDispatch } from 'react-redux'
import { hasNotNote } from '../../redux/notes/services'
import FullNameLine from '../FullNameLine'
function NonMeeting() {
  const dispatch = useDispatch();
  const nonMeetingList = useSelector((state) => state.note.nonMeetingList);
  const [date, setDate] = useState(() => {
    let currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);
    let previousDate = new Date(currentDate).toLocaleDateString('af-ZA', { year: "numeric", month: "numeric", day: "numeric" })
    return previousDate;
  })

  useEffect(() => {

    dispatch(hasNotNote(date))

  }, [dispatch, date]);

  const onChangeDate = (event) => {
    setDate(event.target.value)
  }

  return (
    <>
      <section>
        <div>
          <div className='nonMeetingTitle'>
            <h2>Toplantı Yapılmayan Çalışanlar</h2>
            <input type="date" onChange={onChangeDate} value={date} />
          </div>
          {
            nonMeetingList.length > 0 ?
              nonMeetingList.map((user) => (
                <div key={user.id}>
                  <FullNameLine user={user} />
                </div>
              )
              )
              :
              <div className='nonMeetingNot'>Böyle bir çalışan yok</div>

          }

        </div>
      </section>
    </>
  )
}

export default NonMeeting
