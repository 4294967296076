import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_BASE_URL } from '../../constants'
import { ACCESS_TOKEN } from '../../constants'

export const addTechnicalNote = createAsyncThunk('note/addTechnicalNote', async (data) => {
    const res = await axios.post(`${API_BASE_URL}/notes/${data.id}`, { technical: data.technicalNote, noteStatus: data.noteStatus },
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${ACCESS_TOKEN}` } });
    return res.data;
});

export const updateTechnicalNote = createAsyncThunk('note/updateTechnicalNote', async (data) => {
    const res = await axios.put(`${API_BASE_URL}/notes/${data.id}`, { technical: data.technicalNote, noteStatus: data.noteStatus },
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${ACCESS_TOKEN}` } });
    return res.data;
});

export const getTechnicalNotes = createAsyncThunk('note/getTechnicalNotes', async (id) => {
    const res = await axios.get(`${API_BASE_URL}/employee/${id}/profile/notes`,
        { headers: { "Authorization": `Bearer ${ACCESS_TOKEN}` } });
    return res.data.reverse();
});

export const deleteTechnicalNote = createAsyncThunk('note/deleteTechnicalNote', async (data) => {
    const res = await axios.delete(`${API_BASE_URL}/notes/${data.noteId}`,
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${ACCESS_TOKEN}` } });
    return data;
});


//CoffeeBreak Services
export const addCoffeeBreakNote = createAsyncThunk('note/addCoffeeBreakNote', async (data) => {
    const res = await axios.post(`${API_BASE_URL}/coffeebreaknotes/${data.id}`, { motivation: data.coffeeBreakNote, noteStatus: data.noteStatus },
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${ACCESS_TOKEN}` } });
    return res.data;
});


export const updateCoffeeBreakNote = createAsyncThunk('note/updateCoffeeBreakNote', async (data) => {
    let params = {}
    if (data.coffeeBreakNote) {
        params = {
            motivation: data.coffeeBreakNote, noteStatus: data.noteStatus
        }
    } else {
         params = { noteStatus: data.noteStatus }
    }
    const res = await axios.put(`${API_BASE_URL}/coffeebreaknotes/${data.id}`, { ...params },
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${ACCESS_TOKEN}` } });
    return res.data;
});


export const getCoffeeBreakNotes = createAsyncThunk('note/getCoffeeBreakNotes', async (id) => {
    const res = await axios.get(`${API_BASE_URL}/employee/${id}/profile/coffeebreaknotes`,
        { headers: { "Authorization": `Bearer ${ACCESS_TOKEN}` } });
    return res.data.reverse();
});

export const deleteCoffeeBreakNote = createAsyncThunk('note/deleteCoffeeBreakNote', async (data) => {
    const res = await axios.delete(`${API_BASE_URL}/coffeebreaknotes/${data.noteId}`,
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${ACCESS_TOKEN}` } });
    return data;
});


export const notNumbers = createAsyncThunk('note/notNumbers', async () => {
    const res = await axios.get(`${API_BASE_URL}/noteNumbers`,
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${ACCESS_TOKEN}` } });
    return res.data;
});


export const hasNotNote = createAsyncThunk('note/hasNotNote', async (date) => {
    let data = new FormData();
    data.append('date', date);
    const res = await axios.post(`${API_BASE_URL}/getEmp/hasNotNote/`, data,
        { headers: { "Content-Type": "multipart/form-data", "Authorization": `Bearer ${ACCESS_TOKEN}` } });
    return res.data;
});

export const noteStatusUsers = createAsyncThunk('note/noteStatusUsers', async () => {
    const res = await axios.get(`${API_BASE_URL}/getEmp/byNoteStatus`,
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${ACCESS_TOKEN}` } });
    return res.data;
});

//coffee break comments
export const addCoffeeBreakComment = createAsyncThunk('note/addCoffeeBreakComment', async (data) => {
    const res = await axios.post(`${API_BASE_URL}/cbcomment/${data.id}`, { comment: data.comment },
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${ACCESS_TOKEN}` } });
    return res.data;
});

export const updateCoffeeBreakComment = createAsyncThunk('note/updateCoffeeBreakComment', async (data) => {
    const res = await axios.put(`${API_BASE_URL}/cbcomment/${data.id}`, { comment: data.comment },
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${ACCESS_TOKEN}` } });
    return res.data;
});

export const getCoffeeBreakCommentByNoteId = createAsyncThunk('note/getCoffeeBreakCommentByNoteId', async (noteId) => {
    const res = await axios.get(`${API_BASE_URL}/cbcomment/${noteId}`,
        { headers: { "Authorization": `Bearer ${ACCESS_TOKEN}` } });
    return res.data;
});

export const deleteCoffeeBreakComment = createAsyncThunk('note/deleteCoffeeBreakComment', async (commentId) => {
    const res = await axios.delete(`${API_BASE_URL}/cbcomment/${commentId}`,
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${ACCESS_TOKEN}` } });
    return commentId;
});

//technical comments
export const addTechnicalComment = createAsyncThunk('note/addTechnicalComment', async (data) => {
    const res = await axios.post(`${API_BASE_URL}/comment/${data.id}`, { comment: data.comment },
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${ACCESS_TOKEN}` } });
    return res.data;
});

export const updateTechnicalComment = createAsyncThunk('note/updateTechnicalComment', async (data) => {
    const res = await axios.put(`${API_BASE_URL}/comment/${data.id}`, { comment: data.comment },
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${ACCESS_TOKEN}` } });
    return res.data;
});

export const getTechnicalCommentByNoteId = createAsyncThunk('note/getTechnicalCommentByNoteId', async (noteId) => {
    const res = await axios.get(`${API_BASE_URL}/comment/${noteId}`,
        { headers: { "Authorization": `Bearer ${ACCESS_TOKEN}` } });
    return res.data;
});

export const deleteTechnicalComment = createAsyncThunk('note/deleteTechnicalComment', async (commentId) => {
    const res = await axios.delete(`${API_BASE_URL}/comment/${commentId}`,
        { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${ACCESS_TOKEN}` } });
    return commentId;
});