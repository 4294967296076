import React from 'react'
import './index.scss'

function WarningPopup(props) {
    return (props.trigger) ? (
        <div>
            <div className='popup'>
                <div className='popup-inner'>
                   {props.children}
                </div>
            </div>
        </div>
    ) : "";
}

export default WarningPopup;
